import NextLink from 'next/link';
import { Footer, Header, MainContent, PageWrapper, Meta } from '../components';
import { Text, Heading, Spinner } from '@requity-homes/component-library';
import { getListingsEnvironmentBaseUrl } from '@requity-homes/utils';
import { getCityLink } from '../utils';
import { Cities } from '../const';
import { useRouter } from 'next/router';
import { useState } from 'react';

export default function Index() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  router.events?.on('routeChangeStart', (url) => {
    setIsLoading(true);
  });

  const renderSaskatchewanCities = () => (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-bold">Saskatchewan</h2>
      <div className="flex flex-col gap-2">
        <NextLink passHref href={getCityLink(Cities.Saskatoon)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Saskatoon
          </a>
        </NextLink>
        <NextLink passHref href={getCityLink(Cities.Martensville)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Martensville
          </a>
        </NextLink>
        <NextLink passHref href={getCityLink(Cities.Warman)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Warman
          </a>
        </NextLink>
        <NextLink passHref href={getCityLink(Cities.Regina)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Regina
          </a>
        </NextLink>
        <NextLink passHref href={getCityLink(Cities.PrinceAlbert)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Prince Albert
          </a>
        </NextLink>
        <NextLink passHref href={getCityLink(Cities.MooseJaw)}>
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
          >
            Moose Jaw
          </a>
        </NextLink>
      </div>
    </div>
  );

  const renderWinnipeg = () => (
    <>
      <h2 className="text-lg font-bold mb-4">Manitoba</h2>
      <div className="flex flex-col gap-2">
        <NextLink
          passHref
          href="https://www.realtor.ca/map#ZoomLevel=10&Center=49.853958%2C-97.152560&LatitudeMax=50.14790&LongitudeMax=-96.33271&LatitudeMin=49.55822&LongitudeMin=-97.97241&Sort=6-D&PGeoIds=g30_cbfgtjkk&GeoName=Winnipeg%2C%20MB&PropertyTypeGroupID=1&TransactionTypeId=2&PropertySearchTypeId=0&PriceMin=150000&PriceMax=600000&BuildingTypeId=1&OwnershipTypeGroupId=1&Currency=CAD"
        >
          <a
            rel="noreferrer"
            className="hover:text-coral-medium hover:cursor-pointer"
            target="_blank"
          >
            Winnipeg
          </a>
        </NextLink>
      </div>
    </>
  );

  return (
    <PageWrapper>
      <Meta
        title="Rent to Own Home Listings in Canada | Requity Homes"
        description="View rent-to-own listings for sale in Canada for cities like Edmonton, Saskatoon, Regina, Winnipeg, and many more. Find the best rent-to-own homes near you!"
        ogImage="https://brand-assets.requityhomes.com/open-graph-listing-homepage.png"
        keywords="Rent to Own, Homes"
        ogUrl={getListingsEnvironmentBaseUrl(process.env.NEXT_PUBLIC_STAGE)}
        imageWidth="500"
      />
      <MainContent>
        <Header />
        {isLoading && <Spinner />}
        <div className="flex flex-col justify-between md:h-screen md:-mt-10 md:mb-12">
          <div className="mt-8 md:mt-20">
            <div>
              <div className="flex flex-col gap-4 mb-8 px-5">
                <Heading
                  level="h1"
                  element="h1"
                  fontFace="sans"
                  className="text-center"
                >
                  Rent-to-Own Home Listings in Canada
                </Heading>
                <Text className="text-center px-4 md:px-0" useCase="paragraph">
                  Browse homes available for rent-to-own in our operating
                  markets
                </Text>
              </div>

              <div className="container mx-auto text-center">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-6">
                  <div className="flex flex-col gap-4">
                    <h2 className="text-lg font-bold">Alberta</h2>
                    <div className="flex flex-col gap-2">
                      <NextLink passHref href={getCityLink(Cities.Edmonton)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Edmonton
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.SpruceGrove)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Spruce Grove
                        </a>
                      </NextLink>
                      <NextLink href={getCityLink(Cities.StAlbert)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          St. Albert
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.StonyPlain)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Stony Plain
                        </a>
                      </NextLink>
                      <NextLink
                        passHref
                        href={getCityLink(Cities.SherwoodPark)}
                      >
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Sherwood Park
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.Beaumont)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Beaumont
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.Leduc)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Leduc
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.RedDeer)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Red Deer
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.Lethbridge)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Lethbridge
                        </a>
                      </NextLink>
                      <NextLink
                        passHref
                        href={getCityLink(Cities.GrandePrairie)}
                      >
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Grande Prairie
                        </a>
                      </NextLink>
                      <NextLink passHref href={getCityLink(Cities.Airdrie)}>
                        <a
                          rel="noreferrer"
                          className="hover:text-coral-medium hover:cursor-pointer"
                        >
                          Airdrie
                        </a>
                      </NextLink>
                    </div>
                    <div className="md:hidden">{renderWinnipeg()}</div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div>
                      <h2 className="text-lg font-bold mb-4">Ontario</h2>
                      <div className="flex flex-col gap-2">
                        <NextLink passHref href={getCityLink(Cities.NorthBay)}>
                          <a
                            rel="noreferrer"
                            className="hover:text-coral-medium hover:cursor-pointer"
                          >
                            North Bay
                          </a>
                        </NextLink>
                        <NextLink
                          passHref
                          href={getCityLink(Cities.SaultSteMarie)}
                        >
                          <a
                            rel="noreferrer"
                            className="hover:text-coral-medium hover:cursor-pointer"
                          >
                            Sault Ste. Marie
                          </a>
                        </NextLink>
                        <NextLink passHref href={getCityLink(Cities.Sudbury)}>
                          <a
                            rel="noreferrer"
                            className="hover:text-coral-medium hover:cursor-pointer"
                          >
                            Sudbury
                          </a>
                        </NextLink>
                        <NextLink
                          passHref
                          href={getCityLink(Cities.ThunderBay)}
                        >
                          <a
                            rel="noreferrer"
                            className="hover:text-coral-medium hover:cursor-pointer"
                          >
                            Thunder Bay
                          </a>
                        </NextLink>
                      </div>
                    </div>

                    <div className="hidden md:block mt-4">
                      {renderWinnipeg()}
                    </div>

                    <div className="md:hidden">
                      {renderSaskatchewanCities()}
                    </div>
                  </div>
                  <div className="hidden md:block">
                    {renderSaskatchewanCities()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Text className="text-center mb-2" useCase="small">
            Don’t see your preferred city here? <br className="md:hidden" />
            Send us an email to info@requityhomes.com.
          </Text>
        </div>
        <Footer />
      </MainContent>
    </PageWrapper>
  );
}
